import apple from '../images/apple.svg';
import google from '../images/google.svg';
import facebook from '../images/facebook.svg';
import paypal from '../images/paypal.svg';

export const getSocialProviderImage = (type) => {
  const images = {
    apple,
    'images/apple.svg': apple,
    google,
    'images/google.svg': google,
    facebook,
    'images/facebook.svg': facebook,
    paypal,
    'images/paypal.svg': paypal,
  };
  return images[type] || 'about:blank';
};
