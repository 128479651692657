export const trackIntent = (eventAction, eventInfoType = 'event', attributes = null) => {
  const intent = {
    attributes,
    eventInfo: {
      eventAction,
      type: eventInfoType,
      timeStamp: new Date().toJSON(),
    },
  };

  window.digitalData?.events
    ? window.digitalData.events.push(intent)
    : (window.digitalData = { events: [intent] });
};

export const trackPageView = (pageType, pageID, pageName, eventAction = 'location_change') =>
  trackIntent(eventAction, 'view', {
    page: {
      category: {
        pageType,
      },
      pageInfo: {
        issueDate: new Date().toJSON(),
        pageID,
        pageName,
      },
    },
  });
