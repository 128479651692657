export const AM_URL = process.env.AM_URL;
export const APP_URL = process.env.APP_URL;
export const API_URL = process.env.API_URL;
// Yes, the debugger boolean is intentionally reversed
export const DEBUGGER = process.env.DEBUGGER_OFF !== 'true';
export const JOURNEY_LOGIN = process.env.JOURNEY_LOGIN;
export const JOURNEY_LOGIN_PRO = process.env.JOURNEY_LOGIN_PRO;
export const JOURNEY_LOGIN_PIPE = process.env.JOURNEY_LOGIN_PIPE;
export const JOURNEY_LOGIN_PARTNER = process.env.JOURNEY_LOGIN_PARTNER;
export const JOURNEY_REGISTER = process.env.JOURNEY_REGISTER;
export const JOURNEY_RESET_PASSWORD = process.env.JOURNEY_RESET_PASSWORD;
export const WEB_OAUTH_CLIENT = process.env.WEB_OAUTH_CLIENT;
export const REALM_PATH = process.env.REALM_PATH;
export const SESSION_URL = `${AM_URL}json/realms/root/sessions`;
export const SUCCESS_URL = process.env.SUCCESS_URL;

const metaAppUrl = document.head.querySelector('[name=secure-url]') as HTMLMetaElement | undefined;
export const AppUrl = metaAppUrl?.content || APP_URL;

const metaAmUrl = document.head.querySelector('[name=am-url]') as HTMLMetaElement | undefined;
export const AMUrl = metaAmUrl?.content || AM_URL;

const metaIdFnacBaseUrl = document.head.querySelector('[name=secure-url]') as
  | HTMLMetaElement
  | undefined;
export const ID_FNAC_BASE_URL =
  metaIdFnacBaseUrl?.content || 'https://www.rec2.fr.id.fd-recette.net';

const metaIdFnacSecureUrl = document.head.querySelector('[name=fnacSecureUrl]') as
  | HTMLMetaElement
  | undefined;
export const ID_FNAC_SECURE_URL = metaIdFnacSecureUrl?.content || 'https://www.fnac.com/';
