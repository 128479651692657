import { LangTypes } from './types';

export default (lang: LangTypes) => {
  try {
    return require(`../translations/${lang}.json`);
  } catch (err) {
    console.error(`Unexpected lang '${lang}'. Falling back to frFR.`);
    return require('../translations/fr-FR.json');
  }
};
