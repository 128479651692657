import { useContext, ReactElement } from 'react';

import { AppContext } from '../../global-state';

/**
 * Used to display a loading message
 */
export default function Loading({
  classes = '',
  message,
}: {
  classes?: string;
  /** The message string object passed from the parent component */
  message?: string;
}): ReactElement {
  const [state] = useContext(AppContext);

  return (
    <div className="container">
      <p className={classes}>
        <span className="d-flex justify-content-center my-2">
          <span className="cstm_loading-spinner spinner-border text-primary" role="status"></span>
        </span>
        {message && (
          <span className={`d-flex justify-content-center p-3 fs-5 ${state.theme.textClass}`}>
            {message}
          </span>
        )}
      </p>
    </div>
  );
}
