import { lazy, ReactElement, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ID_FNAC_SECURE_URL } from './constants';

const Login = lazy(() => import('./views/login'));
const LoginPro = lazy(() => import('./views/login-pro'));
const Logout = lazy(() => import('./views/logout'));
const ResetPassword = lazy(() => import('./views/reset-password'));
const Redirect = lazy(() => import('./views/redirect'));

/**
 * @function App - Application React view
 * @returns {Object} - React component object
 */
export default function Router(): ReactElement {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login/:provider">
          <Suspense fallback={null}>
            <Login />
          </Suspense>
        </Route>
        <Route path="/login">
          <Suspense fallback={null}>
            <Login />
          </Suspense>
        </Route>
        <Route path="/login-pro">
          <Suspense fallback={null}>
            <LoginPro />
          </Suspense>
        </Route>
        <Route path="/reset-password">
          <Suspense fallback={null}>
            <ResetPassword />
          </Suspense>
        </Route>
        <Route path="/redirect">
          <Suspense fallback={null}>
            <Redirect />
          </Suspense>
        </Route>
        <Route path="/redirecturl">
          <Suspense fallback={null}>
            <Redirect />
          </Suspense>
        </Route>
        <Route path="/logout">
          <Suspense fallback={null}>
            <Logout />
          </Suspense>
        </Route>
        <Route path="/callback" />
        <Route
          path="/"
          component={() => {
            window.location.href = ID_FNAC_SECURE_URL;
            return null;
          }}
        />
      </Switch>
    </BrowserRouter>
  );
}
