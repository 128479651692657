import { useState, createContext, Dispatch, SetStateAction } from 'react';

/**
 * The global state/store for managing user authentication and page
 * @returns {Array} - Global state values and state methods
 */
type UseGlobalStateMgmtProps = {
  /** User's email */
  email?: string;
  /** User's username */
  username?: string;
  /** Boolean value of user's auth status */
  isAuthenticated?: boolean;
  /** User theme setting */
  prefersDarkTheme: boolean;
};

type ThemeType = {
  mode: string;
  bgClass: string;
  borderClass: string;
  borderHighContrastClass: string;
  cardBgClass: string;
  dropdownClass: string;
  listGroupClass: string;
  navbarClass: string;
  textClass: string;
  textMutedClass: string;
};

/**
 * returns an array with state object as index zero and setters as index one
 */
type GlobalStateMgmtValues = {
  name?: string;
  email?: string;
  theme?: ThemeType;
  isAuthenticated?: boolean;
  /** Set authentification value */
  setAuthentication?: Dispatch<SetStateAction<boolean>>;
  /** Set connected user's email in state and sessionsStorage */
  setEmail?: Dispatch<SetStateAction<string>>;
  /** Set connected user's name in state and sessionsStorage */
  setUser?: Dispatch<SetStateAction<string>>;
};

export function useGlobalStateMgmt({
  email = '',
  username = '',
  isAuthenticated = false,
  prefersDarkTheme,
}: UseGlobalStateMgmtProps): GlobalStateMgmtValues[] {
  /**
   * Create state properties for "global" state.
   * Using internal names that differ from external to prevent shadowing.
   * The destructing of the hook's array results in index 0 having the state value,
   * and index 1 having the "setter" method to set new state values.
   */
  const [authenticated, setAuthentication] = useState(isAuthenticated);
  const [mail, setEmail] = useState(email);
  const [name, setUser] = useState(username);

  /** A wrapper for storing authentication in sessionStorage */
  function setStateAndSession(value: string, to: 'username' | 'email') {
    window.sessionStorage.setItem(`sdk_${to}`, `${value}`);
    to === 'email' ? setEmail(value) : setUser(value);
  }

  const theme = prefersDarkTheme
    ? {
        mode: 'dark',
        bgClass: 'bg-dark',
        borderClass: 'border-dark',
        borderHighContrastClass: 'cstm_border_black',
        cardBgClass: 'cstm_card-dark',
        dropdownClass: 'dropdown-menu-dark',
        listGroupClass: 'cstm_list-group_dark',
        navbarClass: 'cstm_navbar-dark navbar-dark bg-dark text-white',
        textClass: 'text-white',
        textMutedClass: 'text-white-50',
      }
    : {
        mode: 'light',
        bgClass: '',
        borderClass: '',
        borderHighContrastClass: '',
        cardBgClass: '',
        dropdownClass: '',
        listGroupClass: '',
        navbarClass: 'navbar-light bg-white',
        textClass: '',
        textMutedClass: 'text-muted',
      };
  return [
    {
      isAuthenticated: authenticated,
      email: mail,
      theme,
      name,
    },
    {
      setAuthentication,
      setEmail: (e: string) => setStateAndSession(e, 'email'),
      setUser: (e: string) => setStateAndSession(e, 'username'),
    },
  ];
}

/**
 * Creates React Context API
 * This provides the capability to set a global state in React
 * without having to pass the state as props through parent-child components.
 */
export const AppContext = createContext<GlobalStateMgmtValues[]>([{}, {}]);
